@import "@ashishdotme/ui/styles/_theme.sass";

.hero {
  background: #11998e;
  background: -webkit-linear-gradient(to right, #38ef7d, #11998e);
  background: linear-gradient(to right, #38ef7d, #11998e);
  
}

.card {
  border-radius: 5px
}

@media screen and (min-width: 1472px) {
  .container {
    max-width: 1344px;
    width: 1344px;
  }
}
